import React , { useState } from "react";
import classNames from "classnames";
import { connect }  from 'react-redux'
import { logOut } from '../../_actions/userActions'
import { changePassword } from '../../_services/userServices'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import Lock from "@material-ui/icons/Lock";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import Swal from "sweetalert2";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = (props) => {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [ password, setPassword ] = useState('')
  const [ currentPassword, setCurrentPassword ] = useState('')
  const [ passValidation, setPassValidation ] = useState('')

  const { userReducer, dispatch } = props
  const { token, user } = userReducer
  const id = user._id
  
  const handleChangePassword = () => {
    if(password !== passValidation){
      Swal.fire({
        icon : "warning",
        title : "Yeni şifre ve tekrarı uyuşmuyor."
      })
    }else if(currentPassword.length < 6){
      Swal.fire({
        icon : "warning",
        title : "Mevcut şifrenizi eksiksiz giriniz."
      })
    }else if(password.length < 6 ){
      Swal.fire({
        icon : "warning",
        title : "Yeni şifreniz en az 6 karakter uzunluğunda olmalı."
      })
    }else{
      const data = { id, currentPassword, password }
      changePassword(token,data)
      .then( res => {
        if(res.data.code === "200"){
          Swal.fire({
            icon : "success",
            title : "Şifreniz Başarıyla Değiştirildi."
          })
          setPassValidation('')
          setPassword('')
          setCurrentPassword('')
          handleClose()
        }else{
          Swal.fire({
            icon : "warning",
            title : "Şifre değiştirilemedi!"
          })
        }
      })
      .catch( err => {
        Swal.fire({
          icon : "warning",
          title : "Şifre değiştirilemedi!"
        })
      })
    } 
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logOut())
  }

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    }else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profil</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleClickOpen}
                      className={classes.dropdownItem}
                    >
                      Şifre Değiştir
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Çıkış Yap
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <Dialog open={open} onClose={handleClose} style={{zIndex : 15}} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Şifre Değiştir</DialogTitle>
        <DialogContent>
          <CustomInput
              labelText="Mevcut Şifre"
              formControlProps={{
                  fullWidth: true
              }}
              inputProps={{
                  type: "password",
                  name : 'password',
                  value : currentPassword,
                  onChange : e => setCurrentPassword(e.target.value),
                  endAdornment: (
                  <InputAdornment position="end">
                      <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                  )
              }}
          />
          <CustomInput
              labelText="Yeni Şifre"
              formControlProps={{
                  fullWidth: true
              }}
              inputProps={{
                  type: "password",
                  name : 'password',
                  value : password,
                  onChange : e => setPassword(e.target.value),
                  endAdornment: (
                  <InputAdornment position="end">
                      <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                  )
              }}
          />
          <CustomInput
              labelText="Yeni Şifre Tekrar"
              formControlProps={{
                  fullWidth: true
              }}
              inputProps={{
                  type: "password",
                  name : 'password',
                  value : passValidation,
                  onChange : e => setPassValidation(e.target.value),
                  endAdornment: (
                  <InputAdornment position="end">
                      <Lock className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                  )
              }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="rose">
            Vazgeç
          </Button>
          <Button onClick={handleChangePassword} color="rose">
            DEĞİŞTİR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  const { userReducer } = state
  return { userReducer }
}

export default connect(mapStateToProps)(AdminNavbarLinks)
