import axios from 'axios'
import { domain } from './domain'

export const getBrands = (token,data) =>{
    const requestOptions = {
        method : 'POST',
        headers : {
            'Content-type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
        data
    }
    return axios(domain+'/companybrands' ,requestOptions)
    .then( response => {
        if(response.data.code){
            return response
        }else{
            throw new Error('Bağlantı hatası!')
        }})
    .catch( error => { return error })
}

export const getBrandItems = (data) =>{
    const requestOptions = {
        method : 'POST',
        headers : {
            'Content-type' : 'application/json',
        },
        data
    }
    return axios(domain+'/branditemquery' ,requestOptions)
    .then( response => {
        if(response.data.code){
            return response
        }else{
            throw new Error('Bağlantı hatası!')
        }})
    .catch( error => { return error })
}

export const createBrandItem = (token, data) => {
    const requestOptions = {
        method : 'Post',
        headers : {
            'Authorization' : 'Bearer ' + token,
            'Content-type' : 'application/json'
        },
        data
    }
    return axios(domain+'/branditem', requestOptions)
        .then( response => { 
            if(response.data){
                return response
            }else{
                throw new Error('Bağlantı hatası!')
            }})
        .catch( error => { return error })
}

export const editBrandItem = (token, data) => {
    const requestOptions = {
        method : 'PUT',
        headers : {
            'Authorization' : 'Bearer ' + token,
            'Content-type' : 'application/json'
        },
        data
    }
    return axios(domain+'/branditem', requestOptions)
        .then( response => { 
            if(response.data){
                return response
            }else{
                throw new Error('Bağlantı hatası!')
            }})
        .catch( error => { return error })
}

export const getBulkItems = (data) =>{
    const requestOptions = {
        method : 'POST',
        headers : {
            'Content-type' : 'application/json',
        },
        data
    }
    return axios(domain+'/getbulkitems' ,requestOptions)
    .then( response => {
        if(response.data.code){
            return response
        }else{
            throw new Error('Bağlantı hatası!')
        }})
    .catch( error => { return error })
}

export const createBulkItem = (token, data) => {
    const requestOptions = {
        method : 'Post',
        headers : {
            'Authorization' : 'Bearer ' + token,
            'Content-type' : 'application/json'
        },
        data
    }
    return axios(domain+'/bulkitem', requestOptions)
        .then( response => { 
            if(response.data){
                return response
            }else{
                throw new Error('Bağlantı hatası!')
            }})
        .catch( error => { return error })
}

export const editBulkItem = (token, data) => {
    const requestOptions = {
        method : 'PUT',
        headers : {
            'Authorization' : 'Bearer ' + token,
            'Content-type' : 'application/json'
        },
        data
    }
    return axios(domain+'/bulkitem', requestOptions)
        .then( response => { 
            if(response.data){
                return response
            }else{
                throw new Error('Bağlantı hatası!')
            }})
        .catch( error => { return error })
}