import * as userActionTypes  from '../_actionTypes/userActionTypes';

const user = {}
const loading = false
const alert = ''
const loggedIn = false
const token = ''


export const userReducer = (state = { loggedIn, user, loading, alert, token }, action) => {
    switch(action.type){
      case userActionTypes.AUTH:
        return {
          ...state,
          user: action.user,
          loggedIn: true,
          token: action.token,
          loading : false
        }
      case userActionTypes.LOGIN_REQUEST:
        return{
          ...state,
          loading : true,
          alert : ''
        };
      case userActionTypes.LOGIN_SUCCESS:
        return{
          ...state,
          user: action.user,
          loggedIn: true,
          token: action.token
        };
      case userActionTypes.LOGIN_FAILURE:
        return {
          ...state,
          alert : action.alert,
          user : {},
          loading : false
        };  
      case userActionTypes.LOGOUT:
        return {};
      default:
        return state
    }
}
