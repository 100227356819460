import * as utilActionTypes from '../_actionTypes/utilActionTypes'

const restaurantTypes = []
const cities = []
const districts = []
const neighborhoods = []

export const util = (state = { restaurantTypes, cities, districts, neighborhoods}, action) => {
    switch(action.type){
        case utilActionTypes.TYPES:
            return state = {
                ...state,
                restaurantTypes: action.restaurantTypes
            }
        case utilActionTypes.CITIES:
            return state = {
                ...state,
                cities: action.cities
            }
        case utilActionTypes.DISTRICTS:
            return state = {
                ...state,
                districts: action.districts
            }
        case utilActionTypes.NEIGHBORHOODS:
            return state = {
                ...state,
                neighborhoods: action.neighborhoods
            }
        default:
            return state
    }
}