import React, { Component } from 'react'
// import { makeStyles } from "@material-ui/core/styles";

import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.js";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2'
import axios from 'axios'
import { domain } from '../../_services/domain'
import ImageUploader from 'react-images-upload';
import Resizer from 'react-image-file-resizer';
import CircularProgress from '@material-ui/core/CircularProgress';

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

// const styles = theme => ({
//     imgCont : {
//         width: "100%",
//         objectFit: "contain"
//     }
// })
// const useStyles = makeStyles(styles);

class BrandItemImageModal extends Component {
    state = {
        file: '',
        submitting: false
    }

    resizeFile = (file) => new Promise( resolve => {
        Resizer.imageFileResizer(file, 600, 600, 'PNG', 100, 0,
        uri => {
          resolve(uri);
        },
        'blob'
        );
    });

    handleChange = async e => {
        alert()
        let file = e.target.files[0]
        file = await this.resizeFile(file)
        this.setState({
            file
        })
    }

    submitOperation = fd => {
        const { token } = this.props
        this.setState({
            submitting: true
        })
        const requestOptions = {
            method : 'POST',
            headers : { 'Authorization' : 'Bearer ' + token,
                        'Content-type' : 'application/json' 
            },
            data: fd
        }
        return axios(domain+'/branditemimg',requestOptions)
        .then( response => {
            this.setState({
                submitting: false
            })
            if(response.data.code){
                return Swal.fire({
                    icon: 'success',
                    title: 'İşlem Başarılı!',
                    timer: 2000,
                    showConfirmButton: false
                })
            }else{
                return Swal.fire({
                    icon: 'error',
                    title: 'Desteklenmeyen dosya formatı.'
                })
            }
        })
        .then(() => this.props.toggle(''))
        .catch( error => { 
            return Swal.fire({
                    icon: 'error',
                    title: 'Desteklenmeyen dosya formatı.'
                    }) 
        })
    }

    onDrop = async (picture) => {
        if( picture.length > 0){
            let currentFile = picture[0]
            const myFileItemReader = new FileReader()
            const image = await this.resizeFile(currentFile)
            myFileItemReader.addEventListener("load", ()=>{
                // console.log(myFileItemReader.result)
                const myResult = myFileItemReader.result
                this.setState({
                    fileToCrop: myResult,
                    file: image
                    // fileExt: extractImageFileExtensionFromBase64(myResult)
                })
            }, false)

            myFileItemReader.readAsDataURL(currentFile)
        }else{
            this.setState({
                fileToCrop: ''
            });
        }
    }
    
    handleSubmit = e => {
        e.preventDefault();
        const { file } = this.state
        const { id } = this.props
        if(file === ''){
            Swal.fire({
                icon: 'error',
                title: 'Lütfen dosya seçiniz.'
            })
        }else{
            const fd = new FormData();
            fd.append('branditem', file, file.name)
            fd.append('id', id)
            this.submitOperation(fd)    
        }
    }

    render(){
        const { open, toggle } = this.props
        return(
            <Dialog
                open={open}            
                onClose={() => toggle('')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                style={{ zIndex: 15}}
            >
                <DialogTitle id="alert-dialog-slide-title">Ürün Fotoğrafı Düzenle</DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12}>
                            { this.state.fileToCrop &&
                                <img src={this.state.fileToCrop} style={{ maxHeight: 350, width : '100%', objectFit : "contain" }} alt="Profil Fotoğrafı" />
                            }
                        </GridItem>
                        <GridItem xs={12}>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Fotoğraf Seçiniz'
                                onChange={this.onDrop}
                                imgExtension={['.jpg', '.jpeg', '.png']}
                                maxFileSize={5242880}
                                singleImage = {true}
                                fileTypeError = 'Desteklenmeyen dosya formatı!!'
                                fileSizeError = 'Dosya çok büyük!'
                                label = 'Maks 5mb, jpeg veya png.'
                                // withPreview = {true}
                            />
                        </GridItem>
                    </GridContainer>
                    {/* <DialogContentText id="alert-dialog-slide-description">
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    { this.state.submitting
                        ?   <CircularProgress color="secondary" />
                        :   <>
                                <Button color='rose' simple size='lg' onClick={this.handleSubmit} block>Yükle</Button>
                                <Button onClick={() => toggle('')} simple size='lg' color="rose">Kapat</Button>
                            </>
                    }
                    
                </DialogActions>
            </Dialog>
        )
    }
}

export default BrandItemImageModal