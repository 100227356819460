import cover from "assets/img/cover.jpeg";

const loginPageStyles = {
    container : {
        height: "100%",
        width : "98%",
        overFlow : "hidden",
        position: "relative",
        top: "0",
        margin : "0 !important",
    },
    cardHeader : {
        textAlign : "center",
        position : "relative",
    },
    cardHeaderIcon: {
        width : "92%",
    },
    title: {
        color : "#ffffff",
        fontWeight : "500",
    },
    cardContainer : {
        justifyContent: "center !important",
        height : "90vh",
        width : "100%",
        position : "relative",
        top : "0"
    },
    loginCard : {
        position : "relative",
        top : "50%",
        left : "50%",
        transform : "translate(-50%, -50%)",
        zIndex : "15"
    }, 
    bgDiv : {
        position : 'absolute',
        top : "0",
        left : "0",
        width : "100vw",
        height : "100vh",
        backgroundImage: "url(" + cover + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    justifyContentCenter :{
        justifyContent : "center !important",
        height : "60px"
    },
    modalFooterText : {
        fontSize : 12
    },
    backdrop: {
        zIndex: 100,
        color: '#000000',
    },
    textCenter : {
        textAlign : "center"
    },
    logoImage: {
        width: 250,
        display: "inline-block",
        marginTop: 20,
    },
    img: {
        width: 250,
    },
}

export default loginPageStyles