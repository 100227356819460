import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";

import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.js";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2'
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CustomInput from "components/CustomInput/CustomInput.js"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

const styles = theme => ({
    imgCont : {
        width: "100%",
        objectFit: "contain"
    }
})
const useStyles = makeStyles(styles);

const BulkItemModal = props => {
    const [ submitting, setSubmitting ] = useState(false)
    const [ inputValue, setInputValue ] = useState('')
    const { open, toggle, brandItems, brandItem, handleBrandItemChange,handleSubmit, barcode, setBarcode, bulkSize, setBulkSize, bulkPrice, setBulkPrice } = props

    return(
        <Dialog
            open={open}            
            onClose={toggle}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            style={{ zIndex: 500}}
        >
            <DialogTitle id="alert-dialog-slide-title">Toptan Ürün Ekle</DialogTitle>
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={brandItems}
                            onChange = {(e, v) => handleBrandItemChange(e,v)}
                            inputValue = {brandItem}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField fullWidth={true} {...params} label="Ürün Seçiniz" variant="outlined" />}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Koli Barkodu"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                value : barcode,
                                onChange : (e) => setBarcode(e.target.value),
                                name : 'name',
                                type : "text",
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Koli İçindeki Ürün Adedi"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                value : bulkSize,
                                onChange : (e) => setBulkSize(e.target.value),
                                type : "number",
                                endAdornment: (
                                    <p >adet</p>
                                )
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Koli Fiyatı"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                value : bulkPrice,
                                onChange : (e) => setBulkPrice(e.target.value),
                                type : "number",
                                endAdornment: (
                                    <p>₺</p>
                                )
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                { submitting
                    ?   <CircularProgress color="secondary" />
                    :   <>
                            <Button onClick={toggle} block color="rose">Kapat</Button>
                            <Button color='success' onClick={handleSubmit} block>Ekle</Button>
                        </>
                }
                
            </DialogActions>
        </Dialog>
    )
}

export default BulkItemModal