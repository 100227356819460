import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as brandActions from '../../_actions/brand'
import { getBrandItems, editBrandItem, createBrandItem } from '../../_services/brand'
import { makeStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardImage from "components/Card/CardImage.js"
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import CustomInput from "components/CustomInput/CustomInput.js"
import BrandItemImageModal from "components/Modals/BrandItemModal.js"
import Swal from 'sweetalert2'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);


const Items = props=> {
    const classes = useStyles();
    const [ brand, setBrand ] = useState({})
    const [ filter, setFilter ] = useState('')
    const [ brandItems, setBrandItems ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ addMode, setAddMode ] = useState(false)

    const [ itemImageModal, setItemImageModal ] = useState(false)
    const [ id, setId ] = useState('')

    const [ name, setName ] = useState('')
    const [ barcode, setBarcode ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ weight, setWeight ] = useState('')
    const [ unit, setUnit ] = useState('')

    const [ photoId, setPhotoId ] = useState('')

    const { dispatch, companyReducer, brandReducer } = props
    const token = localStorage.getItem('token')

    useEffect(() => {
        if(companyReducer._id){
            const data = { company : companyReducer._id}
            dispatch(brandActions.getBrands(token, data))
        }
    }, [companyReducer])

    const handleBrand = brand => {
        setBrand(brand)
        setLoading(true)
        const data = { brand : brand._id}
        getBrandItems(data)
        .then( res => {
            setLoading(false)
            if(res.data.code === 200){
                setBrandItems(res.data.data)
            }
        })
        .catch( err => setLoading(false))
    }

    const brandCards = brands => {
      return(
        brands.map( brand => {
          return(
            <GridItem key={brand._id} xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color="">
                  <CardImage src={brand.logoUrl} alt={brand.searchUrl} />
                </CardHeader>
                <CardBody>
                  <h3 className={classes.cardTitle}>{brand.name}</h3>
                </CardBody>
                <CardFooter chart>
                  <Button color='rose' onClick={() => handleBrand(brand)} block>Ürünler</Button>
                </CardFooter>
              </Card>
            </GridItem>
          )
        })
      )
    }

    const handleEdit = item => {
        setId(item._id || '')
        setName(item.name || '')
        setBarcode(item.barcode || '')
        setUnit(item.unit || '')
        setWeight(item.weight || '')
    }

    const handleEditSubmit = () => {
        const data = { id, name, barcode, unit, weight, description }
        editBrandItem(token, data)
        .then(
            res => {
                if(res.data.code === 200){
                    handleEdit({id : ""})
                    const index = brandItems.map(i => i._id).indexOf(res.data.data._id)
                    const newItems = [
                        ...brandItems.slice(0, index),
                        res.data.data,
                        ...brandItems.slice(index+1)
                    ]
                    setBrandItems(newItems)
                    Swal.fire({
                        icon : "success",
                        title : "Başarılı!"
                    })
                }
            }
        )
        .catch( err => alert('hata!'))
    }

    const brandItemCards = items => {
        return(
          items.map( item => {
            return(
              <GridItem key={item._id} xs={12} sm={6} md={4}>
                <Card>
                  <CardHeader color="">
                    <CardImage src={item.imgUrl} alt={item.searchUrl} />
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                        <GridItem xs={12}>
                            { id === item._id
                                ?   <CustomInput
                                        labelText="Ürün Adı"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value : name,
                                            onChange : (e) => setName(e.target.value),
                                            name : 'name',
                                            type : "text",
                                            // endAdornment: (
                                            //     <Button color='rose' onClick={() => setBrand({})}>Markalar</Button>
                                            // )
                                        }}
                                    />
                                :   <h3 className={classes.cardTitle}>{item.name}</h3>
                            }
                        </GridItem>
                        <GridItem xs={12}>
                            { id === item._id
                                ?   <GridContainer alignItems="baseline">
                                        <GridItem xs={9}>
                                            <CustomInput
                                                labelText="Ürün Gramajı"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value : weight,
                                                    onChange : (e) => setWeight(e.target.value),
                                                    name : 'name',
                                                    type : "number",
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={unit}
                                                onChange={e => setUnit(e.target.value)}
                                            >
                                                <MenuItem value=''>Seçiniz.</MenuItem>
                                                <MenuItem value='gr'>gr.</MenuItem>
                                                <MenuItem value='kg'>kg.</MenuItem>
                                                <MenuItem value='ml'>ml.</MenuItem>
                                                <MenuItem value='lt'>lt.</MenuItem>
                                            </Select>
                                        </GridItem>
                                    </GridContainer>
                                :   <p>Gramaj : {item.weight} {item.unit}</p>
                            } 
                        </GridItem>
                        <GridItem xs={12}>
                            { id === item._id
                                ?   <CustomInput
                                        labelText="Ürün Barkodu"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value : barcode,
                                            onChange : (e) => setBarcode(e.target.value),
                                            name : 'barcode',
                                            type : "text",
                                            // endAdornment: (
                                            //     <Button color='rose' onClick={() => setBrand({})}>Markalar</Button>
                                            // )
                                        }}
                                    />
                                :   <p>Barkod : {item.barcode}</p>
                            }
                        </GridItem>
                        <GridItem xs={12}>
                            { id === item._id
                                ?   <CustomInput
                                        labelText="Ürün Açıklaması"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value : description,
                                            onChange : (e) => setDescription(e.target.value),
                                            name : 'name',
                                            type : "textArea",
                                            multiline : true,
                                            rows : 4
                                            // endAdornment: (
                                            //     <Button color='rose' onClick={() => setBrand({})}>Markalar</Button>
                                            // )
                                        }}
                                    />
                                :   <p>{item.description}</p>
                            }
                        </GridItem>
                      </GridContainer>
                  </CardBody>
                  <CardFooter chart>
                        { id === item._id
                            ?   <>
                                    <Button color='rose' onClick={() => handleEdit({_id : ''})} block>Vazgeç</Button>
                                    <Button color='success' onClick={() => handleEditSubmit()} block>Güncelle</Button>
                                </>
                            :   <>
                                    <Button color='rose' onClick={() => itemImageModalToggle(item._id)} block>Foto Yükle</Button>
                                    <Button color='rose' onClick={() => handleEdit(item)} block>Düzenle</Button>
                                </>
                        }
                  </CardFooter>
                </Card>
              </GridItem>
            )
          })
        )
    }

    const filteredBrandItems = brandItems.filter( i => i.name.toLowerCase().includes(filter.toLowerCase()))

    const itemImageModalToggle = (id) => {
        setPhotoId(id)
        setItemImageModal(!itemImageModal)
    }

    const handleSubmit = e => {
        const data = { name, barcode, unit, weight, brand: brand._id, description }
        if(name === ''){
            Swal.fire({
                icon : "info",
                title : "Ürün adını giriniz."
            })
        }else if( unit === '' || weight === ''){
            Swal.fire({
                icon : "info",
                title : "Ürün birimini ve gramajını giriniz."
            })
        }else{
            createBrandItem(token,data)
            .then( res => {
                if(res.data.code === 200){
                    Swal.fire({
                        icon : "success",
                        title : "Ürün başarıyla eklendi."
                    })
                    setAddMode(false)
                    setName('')
                    setBarcode('')
                    setWeight('')
                    setUnit('')
                    setDescription('')
                }else{
                    Swal.fire({
                        icon : "eror",
                        title : "Ürün ekleme başarısız."
                    })
                }
            })
            .catch( err => alert(err))
        }
    }

    const handleAddMode = () =>{
        setId('')
        setName('')
        setBarcode('')
        setUnit("")
        setWeight('')
        setAddMode(!addMode)
    }

    return(
      <GridContainer>
        {brand._id 
            ?   <>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color='primary'>
                            <h3>{brand.name} Ürünleri - {brandItems.length} ürün</h3>
                        </CardHeader>
                        <CardBody>
                            <GridContainer alignItems='baseline'>
                                <GridItem xs={12} sm={8}>
                                    <CustomInput
                                        labelText="Ürün filtresi"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value : filter,
                                            onChange : (e) => setFilter(e.target.value),
                                            name : 'filter',
                                            type : "text",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={2}>
                                    <Button color='rose' onClick={handleAddMode}>Ürün Ekle</Button>
                                </GridItem>
                                <GridItem xs={6} sm={2}>
                                    <Button color='rose' onClick={() => setBrand({})}>Markalar</Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                { addMode &&
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color='success'>
                                <h5>Ürün Ekle</h5>
                            </CardHeader>
                            <CardBody>
                                <GridContainer justify='flex-end'>
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="Ürün Adı"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value : name,
                                                onChange : (e) => setName(e.target.value),
                                                name : 'name',
                                                type : "text",
                                                // endAdornment: (
                                                //     <Button color='rose' onClick={() => setBrand({})}>Markalar</Button>
                                                // )
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <GridContainer alignItems="baseline">
                                            <GridItem xs={9}>
                                                <CustomInput
                                                    labelText="Ürün Gramajı"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        value : weight,
                                                        onChange : (e) => setWeight(e.target.value),
                                                        name : 'name',
                                                        type : "number",
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={unit}
                                                    onChange={e => setUnit(e.target.value)}
                                                >
                                                    <MenuItem value=''>Seçiniz.</MenuItem>
                                                    <MenuItem value='gr'>gr.</MenuItem>
                                                    <MenuItem value='kg'>kg.</MenuItem>
                                                    <MenuItem value='ml'>ml.</MenuItem>
                                                    <MenuItem value='lt'>lt.</MenuItem>
                                                </Select>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="Ürün Barkodu"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value : barcode,
                                                onChange : (e) => setBarcode(e.target.value),
                                                name : 'barcode',
                                                type : "text",
                                                // endAdornment: (
                                                //     <Button color='rose' onClick={() => setBrand({})}>Markalar</Button>
                                                // )
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="Ürün Açıklaması"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value : description,
                                                onChange : (e) => setDescription(e.target.value),
                                                name : 'name',
                                                type : "textArea",
                                                multiline : true,
                                                rows : 4
                                                // endAdornment: (
                                                //     <Button color='rose' onClick={() => setBrand({})}>Markalar</Button>
                                                // )
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6} sm={2}>
                                        <Button color='rose' onClick={handleAddMode}>Vazgeç</Button>
                                    </GridItem>
                                    <GridItem xs={6} sm={2}>
                                        <Button color='success' onClick={handleSubmit}>Oluştur</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                }
                { loading
                    ?   <CircularProgress color="secondary" />
                    :   brandItemCards(filteredBrandItems)
                }
                </>
            : brandCards(brandReducer)}
        <BrandItemImageModal 
            open = {itemImageModal}
            toggle = {itemImageModalToggle}
            id = {photoId}
            token = {token}
        />
      </GridContainer>
    )
}

const mapStateToProps = state => {
    const { brandReducer, companyReducer } = state
    return { brandReducer, companyReducer }
}

export default connect(mapStateToProps)(Items)