import * as brandsActionTypes from '../_actionTypes/brand'
import * as brandServices from '../_services/brand'
import Swal from 'sweetalert2'

export const getBrands = (token,data) => {
    return dispatch => {
        brandServices.getBrands(token,data)
        .then(
            response => {
                if(response.data.code !== 200){
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }else{
                    dispatch(success(response.data.data));
                }
            }  
        ).catch( error =>   
            Swal.fire({
                icon: 'error',
                title: 'Marka bilgisi alınırken bir hata oluştu.'
            })
        );
            
    };         
    function success(brands){ return { type : brandsActionTypes.GET, brands}}
}