import * as companyActionTypes from '../_actionTypes/company'
import * as companyServices from '../_services/company'
import Swal from 'sweetalert2'

export const getCompany = (token,data) => {
    return dispatch => {
        companyServices.getCompany(token,data)
        .then(
            response => {
                if(response.data.code !== 200){
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }else{
                    dispatch(success(response.data.data[0]));
                }
            }  
        ).catch( error =>   
            Swal.fire({
                icon: 'error',
                title: 'Şirket bilgisi alınırken bir hata oluştu.'
            })
        );
            
    };         
    function success(company){ return { type : companyActionTypes.GET, company}}
}