import { combineReducers } from 'redux';

import { userReducer } from './userReducer';
import { market } from './marketReducer'
import { util } from './utilReducer'
import { orders } from './orderReducer'
import { staff } from './staffReducer'
import { companyReducer } from './company'
import { brandReducer } from './brand'
 
export const rootReducer = combineReducers({
    userReducer,
    market,
    util,
    orders,
    staff,
    companyReducer,
    brandReducer
});