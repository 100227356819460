/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Sms from "@material-ui/icons/Sms";
// import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AssessmentIcon from '@material-ui/icons/Assessment';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

// project components

import Anasayfa from "views/Anasayfa/Anasayfa.js"
import BulkItems from "views/Items/BulkItems"
import Items from "views/Items/Items.js"
import LoginPage from "views/LoginPage/LoginPage.js"

const dashboardRoutes = [
  {
    path: "/anasayfa",
    name: "Anasayfa",
    icon: Dashboard,
    component: Anasayfa,
    layout: "/admin"
  },
  // {
  //   path: "/markalar",
  //   name: "Markalar",
  //   icon: Sms,
  //   component: Brands,
  //   layout: "/admin"
  // },
  {
    path: "/perakende",
    name: "Perakende Ürünler",
    icon: AssessmentIcon,
    component: Items,
    layout: "/admin"
  },
  {
    path: "/toptan",
    name: "Toptan Ürünler",
    icon: LibraryBooks,
    component: BulkItems,
    layout: "/admin"
  },
  {
    path: "/siparisler",
    name: "Siparişler",
    icon: Notifications,
    component: Anasayfa,
    layout: "/admin"
  },
  {
    path: "/raporlar",
    name: "Raporlar",
    icon: InsertDriveFileIcon,
    component: Anasayfa,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Giriş Yap",
    icon: Dashboard,
    component: LoginPage,
    layout: "/admin",
    hidden : true
  }
];

export default dashboardRoutes;
