import * as staffActionTypes from '../_actionTypes/staffActionTypes'

let kitchenStaff = []
let innerStaff = []
let outerStaff = []

export const staff = ( state = { kitchenStaff, innerStaff, outerStaff }, action) => {
    switch(action.type){
        case staffActionTypes.GETKITCHEN:
            return state = {
                ...state,
                kitchenStaff : action.staff
            }
        case staffActionTypes.UPDATEKITCHEN:
            const ind = state.kitchenStaff.map( s => s._id).indexOf(action.staff._id)
            return state = {
                ...state,
                kitchenStaff : [
                    ...state.kitchenStaff.slice(0, ind),
                    action.staff,
                    ...state.kitchenStaff.slice(ind+1)
                ]
            } 
        case staffActionTypes.GETINNER:
            return state = {
                ...state,
                innerStaff : action.staff
            }
        case staffActionTypes.UPDATEINNER:
            const indx = state.innerStaff.map( s => s._id).indexOf(action.staff._id)
            return state = {
                ...state,
                innerStaff : [
                    ...state.innerStaff.slice(0, indx),
                    action.staff,
                    ...state.innerStaff.slice(indx+1)
                ]
            } 
        case staffActionTypes.GETOUTER:
            return state = {
                ...state,
                outerStaff : action.staff
            }
        case staffActionTypes.UPDATEOUTER:
            const index = state.outerStaff.map( s => s._id).indexOf(action.staff._id)
            return state = {
                ...state,
                outerStaff : [
                    ...state.outerStaff.slice(0, index),
                    action.staff,
                    ...state.outerStaff.slice(index+1)
                ]
            }
        default:
            return state
    }
}