import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as brandActions from '../../_actions/brand'
import { getBrandItems, editBulkItem, createBulkItem, getBulkItems } from '../../_services/brand'
import { makeStyles } from "@material-ui/core/styles";

import {
  GroupingState,
  IntegratedGrouping,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  ColumnChooser,
  SearchPanel,
  TableColumnVisibility,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';


import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import BulkItemModal from "components/Modals/BulkItemModal"
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardImage from "components/Card/CardImage.js"
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Swal from 'sweetalert2'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

const columns = [
  { name : "brandItem", title : "Ürün Adı"},
  { name : "weight", title : "Ürün Gramajı"},
  { name : "unit", title : "Ürün Birimi"},
  { name : "itemBarcode", title : "Ürün Barkodu"},
  { name : "barcode", title : "Koli Barkodu"},
  { name : "bulkSize", title : "Koli Ürün Adedi"},
  { name : "bulkPrice", title : "Koli Fiyatı"},
]


const visibilityMessages = {
  noColumns : "Gösterilecek kolon yok."
}

const groupingMessages = {
  groupByColumn : "Filtrelemek istediğiniz kolonu bu alana sürükleyiniz."
}

const BulkItems = props=> {
    const classes = useStyles();
    const [ brand, setBrand ] = useState({})
    const [ filter, setFilter ] = useState('')
    const [ brandItems, setBrandItems ] = useState([])
    const [ brandItem, setBrandItem ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ grouping, setGrouping ] = useState([])
    const [ searchValue, setSearchValue ] = useState('')
    const [ bulkItems, setBulkItems ] = useState([])
    const [ bulkModal, setBulkModal ] = useState(false)

    const [ barcode, setBarcode ] = useState('')
    const [ bulkSize, setBulkSize ] = useState('')
    const [ bulkPrice, setBulkPrice ] = useState('')

    const bulkModalToggle = () =>{
      setBulkModal(!bulkModal)
    }

    const [ id, setId ] = useState('')

    const { dispatch, companyReducer, brandReducer } = props
    const token = localStorage.getItem('token')

    const handleBrandItemChange = (e,v) => {
      if(v){
        setBrandItem(v._id)
      }else{
        setBrandItem('')
      }
    }

    const rows = list => {
      return (
          list.map( l => { return {
              // id : l.fileId,
              brandItem : l.brandItem.name || '',
              weight : l.brandItem.weight || '',
              unit: l.brandItem.unit || '',
              itemBarcode: l.brandItem.barcode || '',
              barcode: l.barcode || '',
              bulkSize : l.bulkSize || '',
              bulkPrice : (l.bulkPrice || '') + '₺'
          }})
      )
    }

    useEffect(() => {
        if(companyReducer._id){
            const data = { company : companyReducer._id}
            dispatch(brandActions.getBrands(token, data))
        }
    }, [companyReducer])

    useEffect(() => {
      if(brand._id){
          const data = { brand : brand._id}
          getBrandItems(data)
          .then( res => {
              setLoading(false)
              if(res.data.code === 200){
                setBrandItems(res.data.data)
              }
          })
          .catch( err => setLoading(false))      
      }
    }, [brand])

    const handleBrand = brand => {
        setBrand(brand)
        setLoading(true)
        const data = { brand : brand._id}
        getBulkItems(data)
        .then( res => {
            setLoading(false)
            if(res.data.code === 200){
              setBulkItems(res.data.data)
            }
        })
        .catch( err => setLoading(false))
    }

    const brandCards = brands => {
      return(
        brands.map( brand => {
          return(
            <GridItem key={brand._id} xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color="">
                  <CardImage src={brand.logoUrl} alt={brand.searchUrl} />
                </CardHeader>
                <CardBody>
                  <h3 className={classes.cardTitle}>{brand.name}</h3>
                </CardBody>
                <CardFooter chart>
                  <Button color='rose' onClick={() => handleBrand(brand)} block>Ürünler</Button>
                </CardFooter>
              </Card>
            </GridItem>
          )
        })
      )
    }

    const handleSubmit = () => {
      const data = { brandItem, brand : brand._id, bulkSize, barcode, bulkPrice }
      if( brand === ''){
        Swal.fire({
          icon : "error",
          title : "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."
        })
      }else if(brandItem === ''){
        Swal.fire({
          icon : "info",
          title : "Lütfen ürün seçiniz."
        })
      }else if(bulkSize === ''){
        Swal.fire({
          icon : "info",
          title : "Lütfen koli ürün adedi giriniz."
        })
      }else{
        bulkModalToggle()
        createBulkItem(token,data)
        .then( res => {
          if(res.data.code === 200){
            Swal.fire({
              icon : "success",
              title : "Koli başarıyla oluşturuldu."
            })
          }else{
            Swal.fire({
              icon : "error",
              title : "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."
            })
          }
        })
        .catch( err => {
          Swal.fire({
            icon : "error",
            title : "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."
          })
        })
      }
    }


    return(
      <GridContainer>
        {brand._id 
          ? loading
            ?   <CircularProgress color="secondary" />
            : <>
                <GridItem xs={4}>
                  <Button color='success' onClick={bulkModalToggle}>Yeni Toptan Ürün</Button>
                </GridItem>
                <GridItem xs={12}>
                    <Paper style={{ position: 'relative' }}>
                      <Grid
                        rows={rows(bulkItems)}
                        columns={columns}
                      >
                          <SearchState
                            value={searchValue}
                            onValueChange={setSearchValue}
                          />
                          <IntegratedFiltering />
                          <DragDropProvider />
                          <GroupingState
                            grouping={grouping}
                            onGroupingChange={setGrouping}
                          />
                          <IntegratedGrouping />
                          <Table 
                            messages = {{noData : "Hiç kayıt yok."}}
                          />
                          <TableHeaderRow />
                          <TableGroupRow />
                          <TableColumnVisibility
                            defaultHiddenColumnNames={['weight', 'unit', 'itemBarcode']}
                            messages = {visibilityMessages}
                          />
                          <Toolbar />
                          <SearchPanel 
                            messages={{searchPlaceholder : 'Arama..'}}
                          />
                          <GroupingPanel 
                            showGroupingControls 
                            messages = {groupingMessages}
                          />
                          <ColumnChooser
                            messages = {{ showColumnChooser : "Görünen Kolonlar"}}
                          />
                      </Grid>
                  </Paper>
                </GridItem>
              </>
          : brandCards(brandReducer)}
          <BulkItemModal
            open = {bulkModal}
            toggle = {bulkModalToggle}
            brandItems = {brandItems}
            brandItem = {brandItem}
            handleBrandItemChange = {handleBrandItemChange}
            barcode = { barcode }
            setBarcode = { setBarcode }
            setBulkSize = { setBulkSize }
            setBulkPrice = { setBulkPrice }
            bulkSize = { bulkSize }
            bulkPrice = { bulkPrice }
            handleSubmit = { handleSubmit }
          />
      </GridContainer>
    )
}

const mapStateToProps = state => {
    const { brandReducer, companyReducer } = state
    return { brandReducer, companyReducer }
}

export default connect(mapStateToProps)(BulkItems)