import React, { useState } from 'react'
import { login } from '../../_actions/userActions'

import { makeStyles } from "@material-ui/core/styles";

import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Lock from "@material-ui/icons/Lock";

import CircularProgress from '@material-ui/core/CircularProgress';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent"
import CustomInput from "components/CustomInput/CustomInput.js";
import logo from "assets/img/logobw.png";

import styles from "assets/jss/loginPage.js";

const useStyles = makeStyles(styles);

const LoginPage = props => {
    const [ phone, setPhone ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ localAlert, setLocalAlert ] = useState('')
    const { dispatch, userReducer } = props

    const { loading, alert } = userReducer

    const classes = useStyles();

    const handleChange = e => {
        const { name, value } = e.target
        setLocalAlert('')
        if(name === 'phone'){
            setPhone(value)
        }else{
            setPassword(value)
        }
    }

    const handleClick = () => {
        if(phone === ''){
            setLocalAlert('Telefon numarası giriniz.')
            return
        }
        if(password === ''){
            setLocalAlert('Şifre giriniz.')
            return
        }
        const data = { phone, password }
        dispatch(login(data))
    }

    return(
        <div className={classes.container}>
            <GridContainer justify='center'>
                <GridItem xs={10} sm={6} md={4}>
                    <div className={classes.cardContainer}>
                        <Card className={classes.loginCard} >
                            <CardHeader color='rose' className={classes.cardHeader}>
                                    <div className={classes.logoImage}>
                                        <img src={logo} alt="logo" className={classes.img} />
                                    </div>
                                    <h4 className={classes.title}>Giriş Yapınız!</h4>
                            </CardHeader>
                            <CardBody>
                                { alert &&
                                    <SnackbarContent
                                        message = { alert }
                                        color = 'danger'
                                    />
                                }
                                { localAlert &&
                                    <SnackbarContent
                                        message = { localAlert }
                                        color = 'danger'
                                    />
                                }
                                <CustomInput
                                    labelText="Telefon Numaranız"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value : phone ,
                                        onChange : handleChange,
                                        name : 'phone',
                                        type : "number",
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <Face className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                        )
                                    }}
                                />
                                <CustomInput
                                    labelText="Şifre"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "password",
                                        value : password, 
                                        onChange : handleChange ,
                                        name : 'password',
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <Lock className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                        )
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                { loading 
                                    ?   <CircularProgress color="secondary" />
                                    :   <Button color="rose" simple size="lg" block onClick={handleClick}>
                                            GİRİŞ YAP!
                                        </Button>
                                }
                                
                            </CardFooter>
                        </Card>
                    </div>
                </GridItem>
                <div className={classes.bgDiv}></div>
            </GridContainer>
        </div>
    )
}

export default LoginPage