import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
// import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/loginPage.js";

const useStyles = makeStyles(styles);

const Anasayfa = props => {
    const classes = useStyles();

    // const { dispatch } = props

    // const notify = nots => {
    //     return nots.map(
    //         (not, i) => {
    //             if( (not.startDate < Date.now() && Date.now() < not.endDate) || (!not.endDate || !not.startDate) ){
    //                 return(
    //                     <GridItem key={i} xs={10}>
    //                         <SnackbarContent
    //                             message={not.notification}
    //                             color="info"
    //                         />
    //                     </GridItem>
    //                 )
    //             }
    //         }
    //     )
    // }

    return(
        <GridContainer justify='center'>
            <GridItem xs={10} sm={8} md={6}>
                <Card>
                    <CardHeader color='rose' className={classes.cardHeader} >
                        <h4 className={classes.title}>Hazırlanıyor...</h4>
                    </CardHeader>
                    <CardBody justify='center'>
                        <Typography>
                            Bu alan çok yakında düzenlenecektir.
                        </Typography>
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const { userReducer, notificationReducer } = state
    return { userReducer, notificationReducer }
}

export default connect(mapStateToProps)(Anasayfa)