import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const styles = theme => ({
    img : {
        height : "100%",
        width : "100%",
        objectFit : "contain"
    }
})

const useStyles = makeStyles(styles)

const CardImage = props => {
    const classes = useStyles()
    return(
        <LazyLoadImage src={props.src} alt={props.alt} className={classes.img} />
    )
}

export default CardImage