import axios from 'axios'
import { domain } from './domain'

export const getCompany = (token,data) =>{
    const requestOptions = {
        method : 'POST',
        headers : {
            'Content-type' : 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
        data
    }
    return axios(domain+'/getcompanies' ,requestOptions)
    .then( response => {
        if(response.data.code){
            return response
        }else{
            throw new Error('Bağlantı hatası!')
        }})
    .catch( error => { return error })
}