import * as userActionTypes  from '../_actionTypes/userActionTypes';
import * as userServices  from '../_services/userServices';
import Swal from 'sweetalert2';


export const login = (data) => {
    return dispatch => {
        dispatch(request())
        userServices.login(data)
        .then( res => {
            if(res.data.code === "200"){
                dispatch(success(res.data.data, res.data.token))
                localStorage.setItem('token', res.data.token)
            }else{
                dispatch(failure(res.data.message))
            }
        })
        .catch( err => dispatch(failure('Bir Hata Oluştu!')))
    }

    function request(){ return { type : userActionTypes.LOGIN_REQUEST }} 
    function success(user, token){ return { type : userActionTypes.LOGIN_SUCCESS, user }} 
    function failure(alert){ return { type : userActionTypes.LOGIN_FAILURE, alert }} 
}

export const auth = token => {
    return dispatch => {
        userServices.auth(token)
        .then(
            response => {
                let user = response.data.data
                dispatch(success(user, token));
            }  
        ).catch( error => null );
            
    };         
    function success(user, token){ return { type : userActionTypes.AUTH, user,token}}
}

export const userUpdate = (_id,data,token) => {
    return dispatch => {
        userServices.update(_id,data,token)
        .then(response => {
            if(response.data.code !== 200){
                Swal.fire({
                    type: 'error',
                    title: 'Bir hata oluştu!',

                })
            }else{
                dispatch(null)
                Swal.fire({
                    type: 'success',
                    title: 'Başarılı!',
                    text: 'Kullanıcı bilgileri güncellendi.',
                    timer: 2000,
                    showConfirmButton: false
                })
            }
        }  
        ).catch( error =>                 
            Swal.fire({
                type: 'error',
                title: 'Bağlantı Hatası!',
            })
        );
    }
}

export const logOut = () => {
    return dispatch => {
        dispatch(success());
        localStorage.setItem('token', '')
    }
    function success() {return {type : userActionTypes.LOGOUT}}

}

// export const register = (name, phone, password) => {
//     return dispatch => {
//         userServices.register( name, phone, password)
//         .then(response => {
//             if(response.data.code !== "200"){
//                 Swal.fire({
//                     type: 'error',
//                     title: response.data.message,
//                 })
//             }else{
//                 const token = response.data.token
//                 dispatch(success(response.data.data, token));
//                 var d = new Date();
//                 d.setTime(d.getTime() + (30*24*60*60*1000));
//                 var expires = "expires="+ d.toUTCString();
//                 (document.cookie = 'token='+token+';'+expires);
//                 Swal.fire({
//                     type: 'success',
//                     title: 'Hoşgeldiniz!',
//                     text: 'Kullanıcı başarıyla kaydedildi!',
//                     timer: 2000,
//                     showConfirmButton: false
//                 })
//             }
//         }
//         ).catch( error =>
//             Swal.fire({
//                 type: 'error',
//                 title: 'Bağlantı Hatası!',
//             })
//         );
//     };
//     function success(user, token){ return { type : userActionTypes.LOGIN_SUCCESS, user,token}}
// }
