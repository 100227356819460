import * as orderActionTypes  from '../_actionTypes/orderActionTypes';

export const orders = (state = [], action) => {
    switch(action.type){
        case orderActionTypes.CREATE:
          return state = state.concat(action.order)
        case orderActionTypes.GET:
            return action.orders
        case orderActionTypes.UPDATE:
            const index = state.map( o => o._id).indexOf(action.order._id)
            return state = [
                ...state.slice(0, index),
                action.order,
                ...state.slice(index+1)
            ]
        default:
          return state    
    }
}