import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import { auth } from './_actions/userActions'
import { getCompany } from './_actions/company'

// core components
import Admin from "layouts/Admin.js";

import LoginPage from "views/LoginPage/LoginPage.js"

const hist = createBrowserHistory();

const App = props => {

    const token = localStorage.getItem('token')
    const { dispatch, userReducer } = props

    useEffect(() => {
        if(token !== ''){
            dispatch(auth(token))
        }
    },[token, dispatch])

    const { loggedIn, user } = userReducer 

    useEffect(() => {
        if(loggedIn){
            const data = { admins : user._id}
            dispatch(getCompany(token,data))
        }
    }, [loggedIn, dispatch, user._id])

    return(
        <Router history={hist}>
            <Switch>
            <Route path="/admin" render = { () => { return loggedIn ? <Admin {...props} /> : <Redirect to='/login' /> }} />
            {/* <Route path="/admin" component = {Admin}  /> */}

            <Route path='/login' render = { () => { return loggedIn ? <Redirect to='/admin/anasayfa' /> : <LoginPage {...props} /> }} />
            
            <Redirect from="/" to="/admin/anasayfa" />
            </Switch>
        </Router>
    )
}

const mapStateToProps = state => {
    const { userReducer, companyReducer } = state
    return { userReducer, companyReducer }
}

export default connect(mapStateToProps)(App);